import React, { useCallback, useContext } from 'react';
import { type $EntitiesContext, type $EntitiesContextProps, type $EntityAPIItem } from './types';
import useSyncPersistedEntities from './useSyncPersistedEntities';

const defaultEntitiesContext: $EntitiesContext = {
  loading: true,
  getEntity: () => [],
  setEntity: () => ({}),
  entities: undefined,
};

export const PersistedEntitiesContext = React.createContext<$EntitiesContext>({
  ...defaultEntitiesContext,
});

const PersistedEntitiesProvider: React.FC<$EntitiesContextProps> = ({ children }) => {
  const [entities, persistEntity, loading] = useSyncPersistedEntities();

  const getEntity: $EntitiesContext['getEntity'] = useCallback(
    (key) => {
      if (entities && !Array.isArray(key)) {
        return entities[key];
      }
      if (entities && Array.isArray(key)) {
        return entities[key[0]];
      }

      return [];
    },
    [entities],
  );

  const setEntity: $EntitiesContext['setEntity'] = useCallback(
    (key, value, id) => {
      const item: $EntityAPIItem = {
        entityKey: key,
        entityValue: value,
      };

      if (id) {
        item.id = id;
      }

      return persistEntity(item);
    },
    [entities],
  );

  return (
    <PersistedEntitiesContext.Provider value={{ getEntity, setEntity, loading, entities }}>
      {children}
    </PersistedEntitiesContext.Provider>
  );
};

export default PersistedEntitiesProvider;

export const usePersistedEntitiesContext = () => {
  const context = useContext(PersistedEntitiesContext);

  return context;
};
