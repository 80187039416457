import { AppContainer, colors, ErrorMessage, PageContainer, Spinner } from 'component-library';
import { Footer } from '../../Footer';
import { HeaderMenu } from '../../HeaderMenu';

export const LoadingI18n = ({ error }: { error?: boolean }) => {
  return (
    <AppContainer
      backgroundColor={colors.secondary.beigeTint1}
      footer={
        <Footer
          text={{
            privacyInformation: '',
            support: '',
          }}
        />
      }
      header={
        <HeaderMenu
          text={{
            homeLink: '',
            signOut: '',
            contactSupport: '',
            userProfile: '',
            userManagement: '',
            co2Impact: '',
          }}
          serviceNames={{}}
        />
      }
    >
      <PageContainer>
        {error && (
          <ErrorMessage
            title="Error loading content."
            description="Probably failed to call backend."
          />
        )}
        {!error && <Spinner size="xlarge" />}
      </PageContainer>
    </AppContainer>
  );
};
