import { useTranslation } from 'react-i18next';
import { Spinner } from 'component-library';
import { LoadingI18n } from './LoadingI8n';

export const I18nReady = ({
  children,
  simpleLoader,
}: {
  children: React.ReactNode;
  simpleLoader?: boolean;
}) => {
  const { ready } = useTranslation();

  if (!ready) {
    if (simpleLoader) {
      return <Spinner size="xlarge" />;
    }

    return <LoadingI18n />;
  }

  return <>{children}</>;
};
