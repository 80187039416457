import styled from '@emotion/styled';
import { spacing } from 'component-library';

export const MessageContainer = styled.div<{
  type: string;
  border: string;
  mb?: string;
  mt?: string;
}>`
  margin-bottom: ${(props) => props.mb};
  margin-top: ${(props) => props.mt};
  background-color: ${(props) => props.type};
  border: 1px solid ${(props) => props.border};
  border-radius: 8px;
`;

export const MessageBox = styled.div`
  padding: ${spacing.xsmall} ${spacing.xmsmall} ${spacing.xsmall} ${spacing.small};
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: ${spacing.xsmall};
  align-items: stretched;
`;

export const CloseButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const TextWrapper = styled.div<{ closable: boolean }>`
  overflow-wrap: anywhere;
  max-width: ${(props) => (props.closable ? '90%' : '100%')};
`;
