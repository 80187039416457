import { useEffect, useState } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Spinner } from 'component-library';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';
import { useProfileV2 } from '../Profile';
import { I18nReady } from './components/I18nReady';
import { LoadingI18n } from './components/LoadingI8n';
import { initializeI18n } from './InitializeI18n';
import { type I18nContent } from './types';

type ContentProviderProps = {
  children: React.ReactNode;
  applicationName: string;
  resources?: I18nContent;
  simpleLoader?: boolean;
  allowUnauthenticated?: boolean;
  useBackendContentProvider?: boolean;
};

const ContentProvider = ({
  children,
  applicationName,
  resources,
  simpleLoader,
  useBackendContentProvider = true,
}: ContentProviderProps) => {
  const [i18nInitialized, setI18nInitialized] = useState(false);

  const { basicProfile, error } = useProfileV2();

  const { flagsReady } = useFlagsStatus();
  const contentFromS3 = useFlag('content-from-s3');

  useEffect(() => {
    const initialize = async () => {
      if (i18nInitialized) return;

      if (!useBackendContentProvider) {
        await initializeI18n({
          applicationName,
          useOptimizelyBackend: false,
          resources,
          userLang: '',
          contentFromS3: false,
        });
        setI18nInitialized(true);

        return;
      }

      if (!basicProfile) {
        return;
      }

      if (!flagsReady) {
        return;
      }

      const userLang = basicProfile.language;

      await initializeI18n({
        applicationName,
        useOptimizelyBackend: useBackendContentProvider,
        resources,
        userLang,
        contentFromS3,
      });

      setI18nInitialized(true);
    };

    void initialize();
  }, [
    applicationName,
    useBackendContentProvider,
    resources,
    i18nInitialized,
    basicProfile,
    flagsReady,
    contentFromS3,
  ]);

  if (!i18nInitialized) {
    if (simpleLoader) {
      return <Spinner size="xlarge" />;
    }

    return <LoadingI18n error={error} />;
  }

  return <I18nReady>{children}</I18nReady>;
};

export default withAuthenticationRequired(ContentProvider, {
  onRedirecting: () => <LoadingI18n />,
  returnTo: () => {
    return window.location.pathname;
  },
});

export const AllowUnauthenticatedContentProvider = (props: ContentProviderProps) => {
  return <ContentProvider {...props} />;
};
