import { createContext, useContext, type ReactNode } from 'react';

type LocationCount = {
  shouldUseLocationCount: boolean;
};

export type LocationCountProviderProps = {
  children: ReactNode;
  shouldUseLocationCount: boolean;
};

const LocationCountContext = createContext<LocationCount | undefined>(undefined);

export const LocationCountProvider = ({
  shouldUseLocationCount,
  children,
}: LocationCountProviderProps) => {
  const contextValue: LocationCount = { shouldUseLocationCount };

  return (
    <LocationCountContext.Provider value={contextValue}>{children}</LocationCountContext.Provider>
  );
};

export const useLocationCount = (): LocationCount => {
  const contextValue = useContext(LocationCountContext);

  if (!contextValue) {
    throw new Error('useLocationCount must be used within an LocationCountProvider');
  }

  return contextValue;
};
