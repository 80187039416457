import { use, type PostProcessorModule } from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import { getApiUrl } from '../utils/environment';
import { env } from '../../../../helpers/env';
// import { supportedLngs } from './languageUtil';
import { type I18nContent } from './types';

const DISABLE_I18NEXT_LOGGER = env('PORTAL_DISABLE_I18NEXT_LOGGER', 'true') === 'true';

type InitializeI18nFn = {
  applicationName: string;
  useOptimizelyBackend: boolean;
  resources?: I18nContent;
  userLang?: string;
  contentFromS3: boolean;
};
let initialized = false;

const isDebugMode = () => {
  const searchParams = new URLSearchParams(window.location.search);

  const lng = searchParams.get('lng');

  return lng === 'debug' || lng === 'review';
};

const getBackendConfig = (contentFromS3: boolean, applicationName: string) => {
  if (contentFromS3) {
    const baseUrl = env('PORTAL_AWS_S3_ASSETS_URL');

    return {
      loadPath: `${baseUrl}/texts/${applicationName}/{{lng}}.json`,
      customHeaders: {
        accept: 'application/json',
      },
      crossDomain: true,
    };
  }
  const baseUrl = `${getApiUrl()}/content/${applicationName}`;

  return {
    loadPath: `${baseUrl}?lang={{lng}}`,
    customHeaders: {
      accept: 'application/json',
    },
    crossDomain: true,
  };
};

export const initializeI18n = async ({
  applicationName,
  useOptimizelyBackend,
  resources,
  userLang,
  contentFromS3,
}: InitializeI18nFn) => {
  if (initialized) return;

  const textDebugMode = isDebugMode();

  const debugPostProcessor: PostProcessorModule = {
    type: 'postProcessor',
    name: 'debugMode',

    process: (value: string, key: string) => {
      if (textDebugMode) {
        return `${value} (${applicationName} / ${key})`;
      } else {
        return value;
      }
    },
  };

  const i18n = use(detector).use(initReactI18next).use(debugPostProcessor);

  if (useOptimizelyBackend) {
    i18n.use(HttpBackend);
  }

  const backendConfig = getBackendConfig(contentFromS3, applicationName);

  await i18n.init({
    supportedLngs: ['en', 'da', 'sv', 'fi', 'pl', 'no', 'debug'],
    postProcess: 'debugMode',
    debug: !DISABLE_I18NEXT_LOGGER,
    nonExplicitSupportedLngs: true,
    fallbackLng: 'en',
    lng: userLang,
    returnEmptyString: false,
    load: 'languageOnly',
    keySeparator: '.',
    resources: useOptimizelyBackend ? undefined : resources,
    react: {
      useSuspense: false,
    },
    partialBundledLanguages: false,
    backend: backendConfig,
  });
  initialized = true;
};
